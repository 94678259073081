.admin .App {
  text-align: center;
}

.admin .App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .admin .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.admin .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.admin .App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.admin body {
  background-color: white;
}

/*----------------------------------------
  Typography
------------------------------------------*/

@font-face {
  font-family: 'Klinic-slab-bold';
  src: url('./public/assets/klinic_slab/KlinicSlabBold.otf') format('truetype');
}

@font-face {
  font-family: 'Klinic-slab-bold-italic';
  src: url('./public/assets/klinic_slab/KlinicSlabBoldIt.otf') format('truetype');
}

@font-face {
  font-family: 'Klinic-slab-book';
  src: url('./public/assets/klinic_slab/KlinicSlabBook.otf') format('truetype');
}

@font-face {
  font-family: 'Klinic-slab-book-italic';
  src: url('./public/assets/klinic_slab/KlinicSlabBookIt.otf') format('truetype');
}

@font-face {
  font-family: 'Klinic-slab-ligth';
  src: url('./public/assets/klinic_slab/KlinicSlabLight.otf') format('truetype');
}

@font-face {
  font-family: 'Klinic-slab-ligth-italic';
  src: url('./public/assets/klinic_slab/KlinicSlabLightIt.otf') format('truetype');
}

@font-face {
  font-family: 'Klinic-slab-medium';
  src: url('./public/assets/klinic_slab/KlinicSlabMedium.otf') format('truetype');
}

@font-face {
  font-family: 'Klinic-slab-medium-italic';
  src: url('./public/assets/klinic_slab/KlinicSlabMediumIt.otf') format('truetype');
}

.admin h1,
.admin h2,
.admin h3,
.admin h4,
.admin h5,
.admin h6 {
  font-weight: 600;
  text-transform: none;
}

.admin h1 {
  font-family: 'klinic-slab-bold';
  font-size: 40pt;
  line-height: 50px;
}

.admin h2 {
  font-family: 'klinic-slab-medium';
  font-size: 25pt;
  line-height: 35px;
}

.admin h3 {
  font-family: 'klinic-slab-bold';
  font-size: 18pt;
  letter-spacing: 0.1em;
}

.admin h4 {
  font-family: 'klinic-slab-bold';
  font-size: 15pt;
}

.admin h5 {
  font-family: 'klinic-slab-bold';
  font-size: 12pt;
}

.admin h6 {
  font-family: 'klinic-slab-medium';
  font-size: 12pt;
}

.admin p {
  font-family: 'klinic-slab-book';
  font-size: 14pt;
  letter-spacing: 0;
}

.name-pastor {
  font-family: 'klinic-slab-bold';
  font-size: 18pt;
}

 .citation {
  font-family: 'klinic-slab-book-italic';
  font-size: 14pt;
}

 .invalid-feedback {
  font-size: 0.8em !important;
}

.font-size-input {
  font-size: 0.875rem !important;
}

.liste-option {
  height: 30px;
}

 .data-table-wrapper .rdt_TableCol:last-child .rdt_TableCol_Sortable {
  justify-content: inherit !important;
}

.ag-theme-material .ag-header {
  background-color: #f1f2f7;
  text-transform: uppercase;
  font-weight: bold;
}

.breadcrumb {
  margin-bottom: 0 !important;
  --bs-breadcrumb-font-size: 0.9rem !important;
}

.admin .label-switch {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
}

.admin .h-divider {
  margin-top: 5px;
  margin-bottom: 5px;
  height: 1px;
  width: 100%;
  border-top: 1px solid gray;
}

@media (max-width: 520px) {
   .button-unrolled {
    display: flex;
    gap: 10px;
    flex-direction: row; 
  }

   .button-unrolled .btn {
    margin-left: 0 !important;
    margin-top: 5px;
    width: 50%;
  }
}

@media (max-width: 338px) {
   .button-unrolled .btn {
    margin-left: 0 !important;
    margin-top: 5px;
    width: 100%;
  }
}

.admin .custom-scrollbar::-webkit-scrollbar {
  width: 10px;
  height: 6px;
}

.admin .custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.admin .custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.admin .custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.admin .custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

.admin .cursor-pointer {
  cursor: pointer;
}

.admin .tag-relation {
  background-color: #f0f7ff;
  color: #4a90e2;
  padding: 2px 2px;
  border-radius: 5px;
  display: inline-block;
  font-weight: 500;
}

.admin .button-violet {
  background-color: rgb(123, 31, 162) !important;
}

/* Style général du scroll */
::-webkit-scrollbar {
  width: 8px; /* Largeur de la scrollbar */
  height: 6px; /* Hauteur (horizontal) */
}

/* La "piste" (arrière-plan de la scrollbar) */
::-webkit-scrollbar-track {
  background: #f0f0f0; /* Couleur de fond */
  border-radius: 10px; /* Coins arrondis */
}

/* La "poignée" (le thumb de la scrollbar) */
::-webkit-scrollbar-thumb {
  background: #888; /* Couleur de la poignée */
  border-radius: 10px; /* Coins arrondis */
  border: 2px solid #f0f0f0; /* Pour créer un effet d'espace autour */
}

/* Changer la couleur quand on survole avec la souris */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


#multiselect-sites{
  color: grey !important; /* Applique la même couleur que le placeholder de l'input */
  opacity: 1; /* Rend le texte plus clair comme un vrai placeholder */
}



@media screen and (max-width: 1000px) {
  .dynamic-height {
    height: 400px !important;
  }
  .container-logo{
    height: 26% !important;
  }
}

  .logo-image{
    @media screen and (max-width: 1000px) {
      height: 170px;
      }
  }
 